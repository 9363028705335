<template>
  <div>
    <ca-header heading="HSN/TSN Erstellen">
      <template #alert>
        <transition name="slide-fade">
          <ca-alert
            v-if="!!saveErrorMessage"
            variant="danger"
            :message="saveErrorMessage"
            @close="saveErrorMessage = null"
          />
        </transition>
        <transition name="slide-fade">
          <ca-alert
            v-if="!!saveSuccessMessage"
            variant="success"
            :message="saveSuccessMessage"
            @close="saveSuccessMessage = null"
          />
        </transition>
        <transition name="slide-fade">
          <ca-alert
            v-if="showFormInvalidHint"
            variant="info"
            message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
            @close="showFormInvalidHint = null"
          />
        </transition>
      </template>
      <template #buttonSpace>
        <button
          :disabled="savePending"
          class="btn btn-success text-white"
          @click="save()"
        >
          Speichern
        </button>
      </template>
    </ca-header>
    <div class="container-flui px-4 pt-4">
      <HsnTsnForm
        v-if="!loading"
        :hsntsn="hsntsn"
      />
    </div>
  </div>
</template>

<script>
import HsnTsnForm from './components/HsnTsnForm'
import CaHeader from '@/components/Layout/Header'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  name: 'HsnTsnCreate',
  components: {
    HsnTsnForm,
    CaHeader
  },
  data () {
    return {
      loading: false,
      loadingError: null,
      hsntsn: {},
      savePending: false,
      saveError: null,
      saveSuccessMessage: null,
      saveErrorMessage: null,
      showFormInvalidHint: false
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        this.hsntsn = new this.$FeathersVuex.api.Hsntsn()
      } catch (error) {
        this.loadingError = error
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async save () {
      try {
        const valid = await this.$validator.validateAll()
        if (this.userSavePending) {
          return
        }
        if (!valid) {
          this.showFormInvalidHint = true
          return
        }
        this.savePending = true
        this.saveErrorMessage = null
        this.showFormInvalidHint = false
        await this.hsntsn.save()
        this.savePending = false
        this.saveSuccessMessage = 'Speichern erfolgreich'
        await this.$router.push({ name: 'HsnTsnList' })
      } catch (error) {
        if (error.code === 409) {
          this.saveErrorMessage = 'Kombination aus HSN und TSN existiert bereits.'
        } else {
          this.saveErrorMessage = error.message
        }
        this.saveError = error
        console.error(error)
      } finally {
        this.savePending = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

  .site-header{
    z-index: 1020;
  }

  .products-enter-active, .products-leave-active {
    transition: all 300ms;
  }
  .products-enter, .products-leave-to {
    opacity: 0;
    transform: translatex(30px);
  }
</style>
